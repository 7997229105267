import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Seo from '../components/seo';



export default function methodologyPage({data}) {
  const heading = data.methodology.frontmatter.page_headline;
  const content = data.methodology.html;
  return (
    <Layout>
            <Seo title="Methodology" />
          <div className="container methodPage py-3">
          <div className="row py-2">
            <div className="col-md-10">
              <h1>{heading}</h1>
            </div>
        </div>
        <div className="row py-3">
          <div className="col-md-8 methodContent" dangerouslySetInnerHTML={{__html: content }}></div>
        </div>
          </div>
    </Layout>
  )
}

export const query = graphql`
  query MyQuery {
  methodology: markdownRemark(fileAbsolutePath: {regex: "/methodology-page/"}) {
    frontmatter {
      page_headline
    }
    html
  }
}
`
